// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
// import { getDatabase, ref, set, get, remove } from "firebase/database";
import {
  getMetadata,
  getDownloadURL,
  deleteObject,
  getStorage,
  uploadBytes,
  ref,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});
const storage = getStorage(app);

// const database = getDatabase(app);

export async function login() {
  return signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      //const credential = GoogleAuthProvider.credentialFromResult(result);
      //const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      //console.log(user);
      return user;
    })
    .catch((error) => {
      // Handle Errors here.
      //const errorCode = error.code;
      //const errorMessage = error.message;
      // The email of the user's account used.
      //const email = error.customData.email;
      // The AuthCredential type that was used.
      //const credential = GoogleAuthProvider.credentialFromError(error);
      //console.log(error);
    });
}

export async function logout() {
  return signOut(auth)
    .then(() => {
      // Sign-out successful.
      return null;
    })
    .catch((error) => {
      // An error happened.
    });
}

export function onUserStateChange(callback) {
  onAuthStateChanged(auth, async (user) => {
    //const updatedUser = user ? await adminUser(user) : null;
    const updatedUser = user ? await checkUserLevel(user) : user;
    callback(updatedUser);
  });
}

async function checkUserLevel(user) {
  const docRef = doc(firestore, "users", user.uid);
  const docSnapshot = await getDoc(docRef);

  if (docSnapshot.exists()) {
    const userInfo = docSnapshot.data();
    return { ...user, level: userInfo.level };
  }

  setUserInfo({
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    createdAt: new Date(+user.metadata.createdAt),
    level: 0,
  });
  return { ...user, level: 0 };
}

export async function setUsersLevel(users) {
  // Get a new write batch
  const batch = writeBatch(firestore);

  // Update the level of users
  users
    .filter((user) => user.isEdited === true)
    .forEach((user) => {
      const userRef = doc(firestore, "users", user.uid);
      batch.update(userRef, { level: user.level });
    });

  // Commit the batch
  await batch.commit();
}

async function setUserInfo(user) {
  return await setDoc(doc(firestore, "users", user.uid), user);
}

export async function getUsersInfo() {
  const users = [];
  const docSnapshot = await getDocs(collection(firestore, "users"));
  docSnapshot.forEach((doc) => {
    const user = doc.data();
    users.push({ ...user, uid: doc.id, createdAt: user.createdAt.toDate() });
  });

  return users;
}

const generateId = () => {
  const now = new Date();
  const year = now.getFullYear().toString().padStart(4, "0");
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const random = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, "0");
  return `${year}${month}${day}${hours}${minutes}${seconds}-${random}`;
};

export async function getAppraisal(condition) {
  const array = [];
  const docRef = collection(firestore, "appraisals");
  let qDoc = docRef;

  if (condition.id === "new") {
    return [
      {
        id: generateId(),
        appraisal_category: "",
        appraisal_materials: [],
        appraisal_progress: [],
        asign_date: "",
        case_fullnumber: "",
        case_number: "",
        case_number_type: "",
        case_number_remark: "",
        court: "",
        defendant_deposits: [],
        defendant_fee: [],
        defendant_name: "",
        defendant_rep_name: "",
        defendant_rep_telno: "",
        defendant_telno: "",
        file_info: [],
        judge_panel: "",
        judge_panel_email: "",
        judge_panel_faxno: "",
        judge_panel_telno: "",
        object_address: "",
        object_remarks: "",
        plaintiff_deposits: [],
        plaintiff_fee: [],
        plaintiff_name: "",
        plaintiff_rep_name: "",
        plaintiff_rep_telno: "",
        plaintiff_telno: "",
        request_files: [
          { attachment_file: "", file_id: "", type: "감정신청서 원고 1" },
          { attachment_file: "", file_id: "", type: "감정신청서 원고 2" },
          { attachment_file: "", file_id: "", type: "감정신청서 피고 1" },
          { attachment_file: "", file_id: "", type: "감정신청서 피고 2" },
          { attachment_file: "", file_id: "", type: "예상 감정자료 산출근거" },
          { attachment_file: "", file_id: "", type: "감정인 지정 결정문" },
        ],
        status: "",
        submission_date: "",
        title: "",
        total_defendant_fee: 0,
        total_defendant_remainders: 0,
        total_fee: 0,
        total_plaintiff_fee: 0,
        total_plaintiff_remainders: 0,
        total_remainders: 0,
        total_remainders_yn: false,
        trial_progress: [],
        year: "",
      },
    ];
  }

  const cond0 = condition.id;
  const cond1 = condition.case_fullnumber;
  const cond2 = condition.appraisal_category;
  const cond3 = condition.year_from;
  const cond4 = condition.year_to;
  const cond5 = condition.case_number;
  const cond6 = condition.plaintiff_name;
  const cond7 = condition.defendant_name;
  const cond8 = condition.status;
  const cond9 = condition.remainders;

  if (!(cond0 === undefined || cond0 === "")) {
    qDoc = query(qDoc, where("id", "==", cond0));
  }

  if (!(cond1 === undefined || cond1 === "")) {
    qDoc = query(qDoc, where("case_fullnumber", "==", cond1));
  }

  if (!(cond2 === undefined || cond2 === "")) {
    qDoc = query(qDoc, where("appraisal_category", "==", cond2));
  }

  if (!(cond3 === undefined || cond3 === "")) {
    const yearTo = cond3 + "-01-01";
    qDoc = query(qDoc, where("asign_date", ">=", yearTo));
  }

  if (!(cond4 === undefined || cond4 === "")) {
    const yearFrom = cond4 + "-12-31";
    qDoc = query(qDoc, where("asign_date", "<=", yearFrom));
  }

  if (!(cond5 === undefined || cond5 === "")) {
    qDoc = query(qDoc, where("case_number", "==", cond5));
  }

  if (!(cond6 === undefined || cond6 === "")) {
    qDoc = query(qDoc, where("plaintiff_name", "==", cond6));
  }

  if (!(cond7 === undefined || cond7 === "")) {
    qDoc = query(qDoc, where("defendant_name", "==", cond7));
  }

  if (!(cond8 === undefined || cond8 === "")) {
    qDoc = query(qDoc, where("status", "==", cond8));
  }

  if (!(cond9 === undefined || cond9 === "")) {
    if (cond9 === 1)
      qDoc = query(qDoc, where("total_remainders_yn", "==", true));
    else qDoc = query(qDoc, where("total_remainders_yn", "==", false));
  }

  const querySnapshot = await getDocs(qDoc);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    array.push({ ...doc.data(), id: doc.id });
  });
  return array;
}

export async function setAppraisal(action, appraisal) {
  if (action === "set") {
    return await setDoc(doc(firestore, "appraisals", appraisal.id), {
      ...appraisal,
      modify_timestamp: serverTimestamp(),
      create_timestamp:
        appraisal.create_timestamp === undefined
          ? serverTimestamp()
          : appraisal.create_timestamp,
      modify_userid: auth.currentUser.uid,
      create_userid:
        appraisal.create_userid === undefined
          ? auth.currentUser.uid
          : appraisal.create_userid,
    });
  } else {
    return await deleteDoc(doc(firestore, "appraisals", appraisal.id));
  }
}

export async function getMaster(id) {
  const docRef = doc(firestore, "master", id);
  const docSnapshot = await getDoc(docRef);

  const master = [];
  if (docSnapshot.exists()) {
    // const docs = Object.values(docSnapshot.data());
    const docs = docSnapshot.data();
    Object.keys(docs).forEach(function (key) {
      const value = docs[key];
      master.push({ label: value, key, value });
    });
    // docs.forEach((key, value) => master.push({ label: value, value, key }));
  }
  return master;
}

export async function getFileUrl(url) {
  const storageRef = ref(storage, url);

  try {
    // get download url
    const downloadURL = await getDownloadURL(storageRef);
    //console.log(downloadURL);

    // get metadata
    const metadata = await getMetadata(storageRef);
    //console.log(metadata.customMetadata.originFileName);

    return [downloadURL, metadata.customMetadata.originFileName];
  } catch (error) {
    //console.error(error);
    return [];
  }
}

export async function deleteFile(url) {
  // Create a reference to the file to delete
  //console.log(url);
  const desertRef = ref(storage, url);

  // Delete the file
  return deleteObject(desertRef)
    .then(() => {
      // File deleted successfully
      //console.log("삭제성공!");
      return { action: "delete", res: true, url: url };
    })
    .catch((error) => {
      // Uh-oh, an error occurred!
      //console.log("삭제실패!");
      return { action: "delete", res: false, url: url };
    });
}

export async function uploadFile(docId, file) {
  //const storageRef = storage.ref();

  //const storageRef = ref(storage, `${Date.now()}_${file.name}`);
  const storageRef = ref(storage, `${docId}/${Date.now()}_${file.name}`);

  const metadata = {
    customMetadata: {
      originFileName: file.name,
    },
  };

  // 파일 이름 생성
  //const fileName = `${Date.now()}_${file.name}`;

  // Firebase Cloud Storage에 업로드할 파일 경로 생성
  //const fileRef = storageRef.child(fileName);

  /* // 파일 업로드
  fileRef
    .put(file)
    .then((snapshot) => {
      console.log(`File ${fileName} has been uploaded.`);
    })
    .catch((error) => {
      console.error(`Error uploading file: ${error}`);
    }); */

  // 'file' comes from the Blob or File API
  return uploadBytes(storageRef, file, metadata).then((snapshot) => {
    //console.log(snapshot.metadata);
    return { action: "upload", res: true, url: snapshot.metadata.fullPath };
  });
}

export async function setMaster() {
  return await setDoc(doc(firestore, "master", "trial_progress"), {
    0: "자료요청",
    1: "감정회의",
    2: "현장조사",
    3: "원고일부",
  });
}
