import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Contents from "./pages/Contents";
import NewContent from "./pages/NewContent";
import ProtectedRoute from "./pages/ProtectedRoute";
import Users from "./pages/Users";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <App outlet={<NotFound />} />,
    children: [
      {
        index: true,
        element: <Contents />,
      },
      {
        path: "/contents",
        element: (
          <ProtectedRoute>
            <Contents />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contents/new",
        element: (
          <ProtectedRoute>
            <NewContent />
          </ProtectedRoute>
        ),
      },
      {
        path: "/contents/:id",
        element: (
          <ProtectedRoute>
            <NewContent />
          </ProtectedRoute>
        ),
      },
      {
        path: "/admin/users",
        element: (
          <ProtectedRoute adminRequired={true}>
            <Users />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
