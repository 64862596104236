import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUsersInfo } from "../api/firebase";
import { setUsersLevel } from "../api/firebase";

export default function useUsers() {
  const queryClient = useQueryClient();
  const usersQuery = useQuery(
    ["users"],
    async () => {
      const data = await getUsersInfo();
      return data;
    },
    {
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
    }
  );

  const fetchUsers = useMutation((users) => setUsersLevel(users), {
    onSuccess: () =>
      queryClient
        .invalidateQueries
        //   {
        //   queryKey: ["users"],
        // }
        (),
  });

  return { usersQuery, fetchUsers };
}
