import React from "react";

export default function Description({ title, description }) {
  return (
    <div className="flex flex-col">
      <h1 className="flex justify-center font-semibold">{title}</h1>
      <h3 className="flex justify-center text-sm">{description}</h3>
    </div>
  );
}
