import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Outlet } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import Navbar from "./components/Navbar";

function App({outlet}) {
  // Create a client
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <Navbar />
        <div className="px-12">
          {outlet ? outlet : <Outlet />}
        </div>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}

export default App;
