import React from "react";
import Button from "../components/ui/Button";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="flex flex-col place-items-center lg:px-24 md:px-16 sm:px-8 py-5">
      <img
        src="NotFound.png"
        alt="Not Found Geeeeek!"
        className="lg:w-1/2 md:w-3/5 sm:w-4/5"
      />
      <h1 className="font-light text-lg py-5">
        요청하신 정보를 찾을 수 없습니다.
      </h1>
      <Button
        text="홈으로"
        onClick={handleClick}
        theme="primary"
        size="large"
      />
    </div>
  );
}
