import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function YearPicker({ id, onChange, text, required, value }) {
  const [year, setYear] = useState();

  useEffect(() => {
    setYear((value && new Date(value, 0, 1)) || null);
  }, [value]);

  return (
    <div className="flex items-center">
      {text && (
        <label htmlFor={id} className="flex-none w-32">
          {text}
        </label>
      )}
      <DatePicker
        id={id}
        className="grow outline-none border border-light-gray4 my-0 h-9 px-3 text-dark-gray9 w-full box-border"
        selected={year}
        onChange={(date) => {
          date = date ? date : new Date();
          setYear(date);
          onChange({ id, value: date.getFullYear() });
        }}
        showYearPicker
        dateFormat="yyyy"
        required={required}
        yearItemNumber={5}
      />
    </div>
  );
}
