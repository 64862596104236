import React from "react";

export default function SmallButton({ text, onClick, theme }) {
  let css =
    "rounded-lg text-sm px-1 py-0.4 drop-shadow-sm border border-1.5 hover:text-white ";
  switch (theme) {
    case "dark-red":
      css += "border-dark-red text-dark-red hover:bg-dark-red";
      break;
    case "dark-green":
      css += "border-dark-green text-dark-green hover:bg-dark-green";
      break;
    case "indigo":
      css += "border-indigo text-indigo hover:bg-indigo";
      break;
    default:
      break;
  }

  // const css = `rounded-lg text-sm px-1 py-0.5 border border-1.5 border-${theme} text-${theme} hover:text-white hover:bg-${theme}`;

  return (
    <button className={css} onClick={onClick} type="button">
      {text}
    </button>
  );
}
