import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { AiOutlineLoading } from "react-icons/ai";

export default function ProtectedRoute({ children, adminRequired }) {
  const { user } = useAuthContext();

  if (user && user.level === -99) {
    return (
      <div className="w-full flex justify-center p-9">
        <AiOutlineLoading className="animate-spin stroke-2 w-9 h-9 text-indigo" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (user.level < 2) {
    return <Navigate to="/" replace />;
  }

  if (adminRequired && user.level !== 9) {
    alert("관리자만 접근 가능합니다.");
    return <Navigate to="/" replace />;
  }

  return children;
}
