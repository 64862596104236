import React, { useEffect, useState } from "react";
import useUsers from "../hooks/useUsers";
import useMaster from "../hooks/useMaster";
import Button from "../components/ui/Button";
import { DataType, EditingMode } from "ka-table/enums";
import { Table, useTable } from "ka-table";
import { updateCellValue } from "ka-table/actionCreators";
import PageTitle from "../components/ui/PageTitle";

export default function Users() {
  const [tableUsers, setTableUsers] = useState();

  const {
    usersQuery: { data: users },
    fetchUsers,
  } = useUsers();

  const {
    masterQuery: { data: statuses },
  } = useMaster("user_status");

  useEffect(() => {
    setTableUsers(users);
  }, [users]);

  const usersTable = useTable({
    onDispatch: (action) => {
      if (action.type === "UpdateCellValue") {
        setTableUsers((users) => {
          return users.map((user) => {
            if (user.uid === action.rowKeyValue) {
              return {
                ...user,
                [action.columnKey]: +action.value,
                isEdited: true,
              };
            }
            return user;
          });
        });
      }
    },
  });

  const StatusTypeLookupEditor = ({ rowKeyValue, column, dispatch, value }) => {
    const [editorValue, setValue] = useState(value);

    return (
      <div>
        <select
          defaultValue={editorValue}
          onBlur={() => {
            dispatch(updateCellValue(rowKeyValue, column.key, editorValue));
          }}
          onChange={(e) => {
            e.preventDefault();
            setValue(+e.target.value);
          }}
        >
          {statuses &&
            statuses.map(({ key, value }) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
        </select>
      </div>
    );
  };

  const handleClick = (e) => {
    e.preventDefault();
    fetchUsers.mutate(tableUsers, {
      onSuccess: () => {
        alert("회원 등급이 변경되었습니다.");
      },
    });
  };

  return (
    <>
      <PageTitle>사용자 관리</PageTitle>
      <Table
        className="ka-table-custom"
        table={usersTable}
        columns={[
          {
            key: "email",
            title: "이메일",
            dataType: DataType.String,
            isEditable: false,
            width: "1rem",
          },
          {
            key: "displayName",
            title: "이름",
            dataType: DataType.String,
            isEditable: false,
            width: "0.5rem",
          },
          {
            key: "createdAt",
            title: "가입일",
            dataType: DataType.Date,
            isEditable: false,
            width: "0.5rem",
          },
          {
            key: "level",
            title: "등급",
            isEditable: false,
            width: "0.3rem",
          },
        ]}
        rowKeyField={"uid"}
        data={tableUsers}
        format={({ column, value }) => {
          if (column.dataType === DataType.Date) {
            return (
              value &&
              value.toLocaleDateString("ko", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            );
          }
        }}
        editingMode={EditingMode.Cell}
        childComponents={{
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case "level":
                  return <StatusTypeLookupEditor {...props} />;
                default:
                  return null;
              }
            },
          },
        }}
      />
      <div className="flex justify-end mt-3">
        <Button
          text="저장"
          onClick={handleClick}
          size="large"
          theme="primary"
        />
      </div>
    </>
  );
}
