import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAppraisal, setAppraisal } from "../api/firebase";

export default function useAppraisals(condition, isLoaded) {
  const queryClient = useQueryClient();

  const appraisalsQuery = useQuery(
    ["appraisal", condition.id],
    async () => {
      //console.log("firebase 조회!");
      const data = await getAppraisal(condition);
      return data;
    },
    {
      //staleTime: 1000 * 60,
      refetchOnWindowFocus: false,
      //enabled: !isLoaded && condition.case_fullnumber !== undefined,
      //enabled: !isLoaded,
    }
  );

  const fetchAppraisal = useMutation(
    (data) => {
      const { action, appraisalItem } = data;
      setAppraisal(action, appraisalItem);
    },

    {
      onSuccess: () => {
        //console.log("저장에 성공하였는가!!!! ㅎㅎㅎㅎ");
        queryClient.invalidateQueries(["appraisal"]);
      },
    }
  );

  return { appraisalsQuery, fetchAppraisal };
}
