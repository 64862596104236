import { useQuery } from "@tanstack/react-query";
import { getMaster } from "../api/firebase";

export default function useMaster(condition) {
  const masterQuery = useQuery(
    [condition],
    async () => {
      const data = await getMaster(condition);
      return data;
    },
    {
      staleTime: 1000 * 60,
    }
  );

  return { masterQuery };
}
