import React from "react";

export default function Input({
  text,
  id,
  type,
  onChange,
  errMsg,
  value,
  readOnly = false,
  className,
}) {
  return (
    <div className="flex items-center">
      {text && (
        <label htmlFor={id} className="flex-none w-32">
          {text}
        </label>
      )}
      <div className="relative grow">
        <input
          className={`w-full h-9 px-3 box-border text-dark-gray9 ${className}`}
          type={type}
          id={id}
          onChange={onChange}
          value={value || ""}
          readOnly={readOnly}
        />
        {errMsg && errMsg.id === id && errMsg.msg && (
          <div
            className="absolute text-white p-2 text-xs w-max z-10"
            style={{ backgroundColor: "#ff0c0c" }}
          >
            {errMsg.msg}
          </div>
        )}
      </div>
    </div>
  );
}
