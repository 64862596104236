import React from "react";
import { AiOutlineMinus } from "react-icons/ai";
import useMaster from "../hooks/useMaster";
import Input from "./ui/Input";
import Button from "./ui/Button";
import SelectBox from "./ui/SelectBox";
import YearPicker from "./ui/YearPicker";
import GreyCard from "./ui/GreyCard";

export default function SearchForm({ searchCondition, onChange, onSearch }) {
  const {
    masterQuery: { data: statuses },
  } = useMaster("status");

  const {
    masterQuery: { data: appraisalCategories },
  } = useMaster("appraisal_category");

  const remainders = [
    { id: "remainders", value: 1, label: "있음" },
    { id: "remainders", value: 0, label: "없음" },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <GreyCard>
      <section onKeyDown={handleKeyDown}>
        <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-2 overflow-x-auto">
          <div className="flex items-center col-span-1 md:col-span-2">
            <YearPicker
              text="감정지정연도"
              id="year_from"
              onChange={onChange}
              required={true}
            />
            <AiOutlineMinus className="mx-2" />
            <YearPicker id="year_to" onChange={onChange} required={true} />
          </div>
          <Input
            text="사건 번호"
            type="number"
            id="case_number"
            onChange={onChange}
            value={(searchCondition && searchCondition.case_number) || ""}
          />
          <SelectBox
            text="상태"
            id="status"
            options={statuses}
            isClearable={true}
            isSearchable={true}
            onChange={onChange}
          />
          <SelectBox
            text="감정항목분류"
            id="appraisal_category"
            options={appraisalCategories}
            isSearchable={true}
            isClearable={true}
            onChange={onChange}
          />
          <Input
            text="원고 이름"
            type="text"
            id="plaintiff_name"
            onChange={onChange}
            value={(searchCondition && searchCondition.plaintiff_name) || ""}
          />
          <Input
            text="피고 이름"
            type="text"
            id="defendant_name"
            onChange={onChange}
            value={(searchCondition && searchCondition.defendant_name) || ""}
          />

          <SelectBox
            text="미수금 여부"
            id="remainders"
            options={remainders}
            isClearable={true}
            onChange={onChange}
          />
        </div>
        <div className="flex justify-end mt-3 overflow-x-auto">
          <Button text="검색" onClick={onSearch} theme="primary" size="large" />
        </div>
      </section>
    </GreyCard>
  );
}
