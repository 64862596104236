import React from "react";
import { Link } from "react-router-dom";
import { TbFriends } from "react-icons/tb";
import User from "./User";
import Button from "./ui/Button";
import { useAuthContext } from "../context/AuthContext";

export default function Navbar() {
  const { user, login, logout } = useAuthContext();

  return (
    <>
      <header className="flex justify-between border-b bg-dark-gray4 py-1 px-12">
        <Link to="/" className="flex items-center text-xl text-light-gray6">
          <TbFriends />
          <h1 className="font-bold">국양감정관리</h1>
        </Link>
        <section className="flex items-center gap-4 font-semibold">
          {user && <User user={user} />}
          {!user && (
            <Button text={"로그인"} onClick={login} theme="transparent-light" />
          )}
          {user && (
            <Button
              text={"로그아웃"}
              onClick={logout}
              theme="transparent-light"
            />
          )}
        </section>
      </header>
      <nav className="flex justify-between text-base font-semibold py-2 px-12 border-b border-light-gray4">
        <section className="flex items-center gap-4">
          <Link to="/contents">감정목록조회</Link>
          <Link to="/contents/new">감정상세</Link>
        </section>
        {user && user.level === 9 && (
          <section>
            <Link to="/admin/users">사용자관리</Link>
          </section>
        )}
      </nav>
    </>
  );
}
