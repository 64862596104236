import React, { useState } from "react";
import "ka-table/style.css";

import { Table } from "ka-table";
import { DataType, SortingMode } from "ka-table/enums";
import useAppraisals from "../hooks/useAppraisals";
import { useNavigate } from "react-router-dom";
import SearchForm from "../components/SearchForm";
import { useAuthContext } from "../context/AuthContext";
import PageTitle from "../components/ui/PageTitle";
import Description from "../components/ui/Description";

export default function Contents() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [searchCondition, setSearchCondition] = useState({
    case_fullnumber: "",
  });

  const {
    appraisalsQuery: { data: appraisals, refetch },
  } = useAppraisals(searchCondition, false);

  const handleChange = (e) => {
    const { id, value } = e?.target ?? e;
    setSearchCondition((prev) => ({ ...prev, [id]: value }));
  };

  const handleSearch = (e) => {
    refetch();
  };

  return (
    <>
      <PageTitle>감정목록조회</PageTitle>
      <SearchForm
        searchCondition={searchCondition}
        onChange={handleChange}
        onSearch={handleSearch}
      />
      {!user && (
        <Description
          title="로그인이 필요합니다."
          description="오른쪽 상단의 로그인을 클릭하세요."
        />
      )}
      {user && user.level === 0 && <Description title="승인 대기 중입니다." />}
      {user && user.level === 1 && (
        <Description title="조회 권한이 없습니다." />
      )}
      {user && user.level > 1 && (
        <Table
          className="ka-table-custom"
          columns={[
            {
              key: "court",
              title: "법원",
              dataType: DataType.String,
              width: "0.3rem",
            },
            {
              key: "case_fullnumber",
              title: "사건 번호",
              dataType: DataType.String,
              width: "0.3rem",
            },
            {
              key: "title",
              title: "사건 명",
              dataType: DataType.String,
              width: "0.4rem",
            },
            {
              key: "appraisal_category",
              title: "감정항목분류",
              dataType: DataType.String,
              width: "0.3rem",
            },
            {
              key: "status",
              title: "상태",
              dataType: DataType.String,
              width: "0.3rem",
            },
            {
              key: "plaintiff_name",
              title: "원고 이름",
              dataType: DataType.String,
              width: "0.35rem",
            },
            {
              key: "defendant_name",
              title: "피고 이름",
              dataType: DataType.String,
              width: "0.35rem",
            },
            {
              key: "total_remainders",
              title: "감정료 잔액",
              dataType: DataType.String,
              style: { textAlign: "right" },
              width: "0.35rem",
            },
          ]}
          format={({ column, value }) => {
            if (column.key === "total_remainders") {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }}
          data={appraisals}
          rowKeyField={"id"}
          sortingMode={SortingMode.Single}
          childComponents={{
            dataRow: {
              elementAttributes: () => ({
                onClick: (e, extendedEvent) => {
                  const {
                    childProps: { rowKeyValue },
                  } = extendedEvent;
                  navigate(`/contents/${rowKeyValue}`);
                },
                onMouseEnter: (event, extendedEvent) => {
                  const {
                    childProps: { rowKeyValue },
                    dispatch,
                  } = extendedEvent;
                  dispatch({ type: "ROW_MOUSE_ENTER", rowKeyValue });
                },
                onMouseLeave: (event, { dispatch }) => {
                  dispatch({ type: "ROW_MOUSE_LEAVE" });
                },
              }),
            },
          }}
        />
      )}
      <div className="h-14"></div>
    </>
  );
}
