import React, { useEffect, useState } from "react";
import Select from "react-select";

export default function SelectBox({
  text,
  id,
  value,
  options,
  isSearchable,
  isClearable,
  onChange,
}) {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
    onChange({ ...selectedOption, id });
  };

  return (
    <div className="flex items-center">
      {text && (
        <label htmlFor={id} className="flex-none w-32">
          {text}
        </label>
      )}
      <Select
        id={id}
        value={selectedValue}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        onChange={handleSelectChange}
        className="grow"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        menuPortalTarget={document.body}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused
              ? "var(--apple-indigo)"
              : "var(--apple-lightgray4)",
          }),
        }}
      />
    </div>
  );
}
