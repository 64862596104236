import React from "react";

export default function Button({ id, text, onClick, theme, size }) {
  let cssClass = "rounded-sm hover:brightness-90";
  switch (theme) {
    case "transparent-light":
      cssClass += " text-light-gray6";
      break;
    case "primary":
      cssClass += " text-light-gray6 bg-indigo";
      break;
    case "delete":
      cssClass += " text-light-gray6 bg-dark-red";
      break;
    default:
      break;
  }

  switch (size) {
    case "large":
      cssClass += " py-2 px-8";
      break;
    case "full":
      cssClass += " w-full py-2 px-4";
      break;
    default:
      cssClass += " py-2 px-4";
      break;
  }

  return (
    <button id={id} className={cssClass} onClick={onClick}>
      {text}
    </button>
  );
}
