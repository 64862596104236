import { useState } from "react";
import { useParams } from "react-router-dom";
import useAppraisals from "../hooks/useAppraisals";
import { Table, useTable } from "ka-table";
import { DataType, EditingMode } from "ka-table/enums";
import {
  closeEditor,
  updateCellValue,
  updateEditorValue,
  showNewRow,
  saveNewRow,
  hideNewRow,
  deleteRow,
} from "ka-table/actionCreators";
import useMaster from "../hooks/useMaster";
import { deleteFile, getFileUrl, uploadFile } from "../api/firebase";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import { FiDelete } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Button from "../components/ui/Button";
import PageTitle from "../components/ui/PageTitle";
import GreyCard from "../components/ui/GreyCard";
import Input from "../components/ui/Input";
import SelectBox from "../components/ui/SelectBox";
import YearPicker from "../components/ui/YearPicker";
import Subtitle from "../components/ui/Subtitle";
import SmallButton from "../components/ui/SmallButton";

export default function NewContent() {
  const { id } = useParams();
  const [item, setItem] = useState({});
  // eslint-disable-next-line
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();

  const {
    masterQuery: { data: feeCategory },
  } = useMaster("fee_category");

  const {
    masterQuery: { data: court },
  } = useMaster("court");

  const {
    masterQuery: { data: status },
  } = useMaster("status");

  const {
    masterQuery: { data: appraisal_progress },
  } = useMaster("appraisal_progress");

  const {
    masterQuery: { data: trial_progress },
  } = useMaster("trial_progress");

  const {
    masterQuery: { data: appraisal_category },
  } = useMaster("appraisal_category");

  const {
    masterQuery: { data: parties },
  } = useMaster("parties");

  const condition = {
    id: id === undefined ? "new" : id,
  };

  const {
    appraisalsQuery: { data: appraisals },
    fetchAppraisal,
  } = useAppraisals(condition, isLoaded);

  useEffect(() => {
    if (Array.isArray(appraisals) && appraisals.length === 0) {
      navigate("/contents");
    } else if (appraisals !== undefined) {
      setItem(appraisals[0]);
    }
    // eslint-disable-next-line
  }, [appraisals, id]);

  const [errMsg, setErrMsg] = useState(null);
  const handleChange = (e) => {
    const { id, value } = e?.target ?? e;
    let case_fullnumber = "";

    // 검증로직을 처리합니다.
    switch (id) {
      case "year":
        // if (!value) {
        //   setErrMsg({ id: id, msg: "필수입력 항목입니다." });
        // } else if (value.trim().length !== 4 || isNaN(value)) {
        //   setErrMsg({ id: id, msg: "숫자 4자리를 입력해야 합니다." });
        // } else {
        //   setErrMsg(null);
        // }
        break;
      case "case_number_type":
        if (!value) {
          setErrMsg({ id: id, msg: "필수입력 항목입니다." });
        } else {
          setErrMsg(null);
        }
        break;
      case "case_number":
        if (!value) {
          setErrMsg({ id: id, msg: "필수입력 항목입니다." });
        } else if (isNaN(value)) {
          setErrMsg({ id: id, msg: "숫자를 입력해야 합니다." });
        } else {
          setErrMsg(null);
        }
        break;
      case "asign_date":
        if (!value) {
          setErrMsg({ id: id, msg: "필수입력 항목입니다." });
        } else {
          setErrMsg(null);
        }
        break;
      case "title":
        if (!value) {
          setErrMsg({ id: id, msg: "필수입력 항목입니다." });
        } else {
          setErrMsg(null);
        }
        break;
      default:
        break;
    }

    switch (id) {
      case "year":
        case_fullnumber = `${value}${item.case_number_type || ""}${
          item.case_number || ""
        }${item.case_number_remark || ""}`;
        break;
      case "case_number_type":
        case_fullnumber = `${item.year || ""}${value}${item.case_number || ""}${
          item.case_number_remark || ""
        }`;
        break;
      case "case_number":
        case_fullnumber = `${item.year || ""}${
          item.case_number_type || ""
        }${value}${item.case_number_remark || ""}`;
        break;
      case "case_number_remark":
        case_fullnumber = `${item.year || ""}${item.case_number_type || ""}${
          item.case_number || ""
        }${value}`;
        break;
      default:
        case_fullnumber = `${item.year || ""}${item.case_number_type || ""}${
          item.case_number || ""
        }${item.case_number_remark || ""}`;
        break;
    }
    setItem((item) => ({ ...item, [id]: value, case_fullnumber }));
  };

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (submitting) {
      setSubmitting(false);
      fetchAppraisal.mutate(
        { action: "set", appraisalItem: item },
        {
          onSuccess: () => {
            alert("저장성공!");
            navigate(`/contents/${item.id}`);
          },
        }
      );
    }
    // eslint-disable-next-line
  }, [submitting]);

  const [isUpdateRemainder, setIsUpdateRemainder] = useState(false);
  useEffect(() => {
    if (isUpdateRemainder === true) {
      setRemainders();
    }
    // eslint-disable-next-line
  }, [item]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const promises = [];

    if (e.target.id === "del_doc") {
      if (id === undefined) {
        alert("신규문서는 삭제할 수 없습니다!");
        return;
      }
      // eslint-disable-next-line no-restricted-globals
      if (!confirm("정말 삭제하시겠습니까?")) {
        return;
      }
      for (let i = 0; i < item.file_info.length; i++) {
        promises.push(deleteFile(item.file_info[i].url));
      }

      Promise.all(promises).then((results) => {
        fetchAppraisal.mutate(
          { action: "delete", appraisalItem: item },
          {
            onSuccess: () => {
              alert("삭제성공!");
              navigate("/contents");
            },
          }
        );
      });

      return;
    }

    const validations = [
      { field: "year", errorMsg: "필수입력 항목입니다.", check: !item.year },
      // {
      //   field: "year",
      //   errorMsg: "숫자 4자리를 입력해야 합니다.",
      //   check: item.year.trim().length !== 4 || isNaN(item.year),
      // },
      {
        field: "case_number_type",
        errorMsg: "필수입력 항목입니다.",
        check: !item.case_number_type,
      },
      {
        field: "case_number",
        errorMsg: "필수입력 항목입니다.",
        check: !item.case_number,
      },
      {
        field: "case_number",
        errorMsg: "숫자를 입력해야 합니다.",
        check: isNaN(item.case_number),
      },
      {
        field: "asign_date",
        errorMsg: "필수입력 항목입니다.",
        check: !item.asign_date,
      },
      { field: "title", errorMsg: "필수입력 항목입니다.", check: !item.title },
    ];

    let focusField = null; // 저장할 포커스 필드
    for (const validation of validations) {
      if (validation.check) {
        setErrMsg({ id: validation.field, msg: validation.errorMsg });
        focusField = validation.field; // 포커스 필드 설정
        break;
      }
    }

    // 포커스 이동 처리
    if (focusField) {
      const element = document.getElementById(focusField);
      if (element) {
        element.focus();
      }
      return;
    } else {
      setErrMsg(null);
    }

    for (let i = 0; i < item.file_info.length; i++) {
      if (item.file_info[i].del_yn !== undefined) {
        promises.push(deleteFile(item.file_info[i].url));
      } else if (item.file_info[i].url !== undefined) {
        promises.push({ action: "none", url: item.file_info[i].url });
      } else {
        promises.push(uploadFile(item.id, item.file_info[i].file));
      }
    }

    Promise.all(promises).then((results) => {
      // 결과를 처리하는 코드 작성
      setItem((prevItem) => ({
        ...prevItem,
        file_info: prevItem.file_info.map((fileItem, index) => ({
          file_id: fileItem.file_id,
          url: results[index].url,
        })),
        appraisal_progress: prevItem.appraisal_progress.map((child, index) => ({
          ...child,
          file_id: child.attachment_file === "" ? "" : child.file_id,
        })),
        request_files: prevItem.request_files.map((child, index) => ({
          ...child,
          file_id: child.attachment_file === "" ? "" : child.file_id,
        })),
      }));

      const deletedFiles = results.filter(
        (result) => result.action === "delete"
      );

      if (deletedFiles.length > 0) {
        setItem((prevItem) => ({
          ...prevItem,
          file_info: prevItem.file_info.filter((fileItem) => {
            return !deletedFiles.some(
              (deletedFile) => deletedFile.url === fileItem.url
            );
          }),
        }));
      }

      //return;
      setSubmitting(true);
    });
  };

  const tableDefendantFee = useTable({
    onDispatch: (action, state) => {
      setIsUpdateRemainder(true);
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          defendant_fee: prevItem.defendant_fee.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        setItem((prevItem) => ({
          ...prevItem,
          defendant_fee: prevItem.defendant_fee.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.defendant_fee.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              newRow.type = newRow.type || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                defendant_fee: [newRow, ...prevItem.defendant_fee],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const setRemainders = () => {
    let defendantFee = 0;
    let defendantDeposits = 0;
    let defendantRemainders = 0;
    let plaintiffFee = 0;
    let plaintiffDeposits = 0;
    let plaintiffRemainders = 0;
    let totalRemainders = 0;
    let totalRemaindersYn = false;

    item.defendant_fee.forEach(function (obj) {
      defendantFee += obj.amount;
    });
    item.defendant_deposits.forEach(function (obj) {
      defendantDeposits += obj.amount;
    });
    defendantRemainders = defendantFee - defendantDeposits;

    item.plaintiff_fee.forEach(function (obj) {
      plaintiffFee += obj.amount;
    });
    item.plaintiff_deposits.forEach(function (obj) {
      plaintiffDeposits += obj.amount;
    });
    plaintiffRemainders = plaintiffFee - plaintiffDeposits;
    totalRemainders = defendantRemainders + plaintiffRemainders;
    totalRemaindersYn = totalRemainders > 0;

    setIsUpdateRemainder(false);
    setItem((prevItem) => ({
      ...prevItem,
      total_defendant_fee: defendantFee,
      total_defendant_remainders: defendantRemainders,
      total_plaintiff_fee: plaintiffFee,
      total_plaintiff_remainders: plaintiffRemainders,
      total_fee: defendantFee + plaintiffFee,
      total_remainders: totalRemainders,
      total_remainders_yn: totalRemaindersYn,
    }));
  };

  // 피고 입금 테이블
  const tableDefendantDeposits = useTable({
    onDispatch: (action, state) => {
      setIsUpdateRemainder(true);
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          defendant_deposits: prevItem.defendant_deposits.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        setItem((prevItem) => ({
          ...prevItem,
          defendant_deposits: prevItem.defendant_deposits.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.defendant_deposits.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              newRow.type = newRow.type || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                defendant_deposits: [newRow, ...prevItem.defendant_deposits],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const tablePlaintiffFee = useTable({
    onDispatch: (action, state) => {
      setIsUpdateRemainder(true);
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          plaintiff_fee: prevItem.plaintiff_fee.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        setItem((prevItem) => ({
          ...prevItem,
          plaintiff_fee: prevItem.plaintiff_fee.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.plaintiff_fee.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              newRow.type = newRow.type || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                plaintiff_fee: [newRow, ...prevItem.plaintiff_fee],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const tablePlaintiffDeposits = useTable({
    onDispatch: (action, state) => {
      setIsUpdateRemainder(true);
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          plaintiff_deposits: prevItem.plaintiff_deposits.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        setItem((prevItem) => ({
          ...prevItem,
          plaintiff_deposits: prevItem.plaintiff_deposits.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.plaintiff_deposits.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              newRow.type = newRow.type || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                plaintiff_deposits: [newRow, ...prevItem.plaintiff_deposits],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const tableRequestFiles = useTable({
    onDispatch: (action) => {
      if (action.type === "UpdateCellValue") {
        setItem((prevItem) => ({
          ...prevItem,
          request_files: prevItem.request_files.map((child) =>
            child.type === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      }
    },
  });

  const tableTrialProgress = useTable({
    onDispatch: (action, state) => {
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          appraisal_progress: prevItem.trial_progress.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        if (action.columnKey === "date") {
          const date = new Date(action.value);
          const year = date.getFullYear();
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          const day = ("0" + date.getDate()).slice(-2);
          const desiredDateString = `${year}-${month}-${day}`;

          action.value = desiredDateString;
        }

        setItem((prevItem) => ({
          ...prevItem,
          trial_progress: prevItem.trial_progress.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.trial_progress.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              newRow.type = newRow.type || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                trial_progress: [newRow, ...prevItem.trial_progress],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const tableAppraisalProgress = useTable({
    onDispatch: (action, state) => {
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          appraisal_progress: prevItem.appraisal_progress.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        if (
          action.columnKey === "start_date" ||
          action.columnKey === "end_date"
        ) {
          const date = new Date(action.value);
          const year = date.getFullYear();
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          const day = ("0" + date.getDate()).slice(-2);
          const desiredDateString = `${year}-${month}-${day}`;

          action.value = desiredDateString;
        }

        setItem((prevItem) => ({
          ...prevItem,
          appraisal_progress: prevItem.appraisal_progress.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.appraisal_progress.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              //newRow.type = tempStatusType || "";
              newRow.type = newRow.type || "";
              newRow.attachment_file = newRow.attachment_file || "";
              newRow.file_id = newRow.file_id || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.start_date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.start_date = desiredDateString;

              date = new Date(newRow.end_date);
              year = date.getFullYear();
              month = ("0" + (date.getMonth() + 1)).slice(-2);
              day = ("0" + date.getDate()).slice(-2);
              desiredDateString = `${year}-${month}-${day}`;
              newRow.end_date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                appraisal_progress: [newRow, ...prevItem.appraisal_progress],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const tableAppraisalMaterials = useTable({
    onDispatch: (action, state) => {
      if (action.type === "DeleteRow") {
        setItem((prevItem) => ({
          ...prevItem,
          appraisal_materials: prevItem.appraisal_materials.filter(
            (child) => child.index !== action.rowKeyValue
          ),
        }));
      } else if (action.type === "UpdateCellValue") {
        if (action.columnKey === "date") {
          const date = new Date(action.value);
          const year = date.getFullYear();
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          const day = ("0" + date.getDate()).slice(-2);
          const desiredDateString = `${year}-${month}-${day}`;

          action.value = desiredDateString;
        }

        setItem((prevItem) => ({
          ...prevItem,
          appraisal_materials: prevItem.appraisal_materials.map((child) =>
            child.index === action.rowKeyValue
              ? { ...child, [action.columnKey]: action.value }
              : child
          ),
        }));
      } else if (action.type === "SaveNewRow") {
        setItem((prevItem) => {
          const existingIndex = prevItem.appraisal_materials.findIndex(
            (row) => row.index === action.rowKeyValue
          );
          if (existingIndex === -1) {
            let newRow = state.data.find(
              (row) => row.index === action.rowKeyValue
            );

            if (newRow) {
              newRow.parties = newRow.parties || "";
              newRow.attachment_file = newRow.attachment_file || "";
              newRow.file_id = newRow.file_id || "";
              newRow.remarks = newRow.remarks || "";

              let date = new Date(newRow.date);
              let year = date.getFullYear();
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let day = ("0" + date.getDate()).slice(-2);
              let desiredDateString = `${year}-${month}-${day}`;
              newRow.date = desiredDateString;

              delete newRow.addColumn;

              return {
                ...prevItem,
                appraisal_materials: [newRow, ...prevItem.appraisal_materials],
              };
            }
          }
          return prevItem;
        });
      }
    },
  });

  const TypeLookupEditor = ({
    column,
    dispatch,
    rowKeyValue,
    value,
    dataSet,
  }) => {
    const close = () => {
      dispatch(closeEditor(rowKeyValue, column.key));
    };
    const [editorValue, setValue] = useState(value);

    return (
      <div className="relative border border-black bg-white">
        <select
          className="w-full focus:border-none focus:outline-none"
          autoFocus={true}
          defaultValue={editorValue}
          onBlur={() => {
            if (Object.entries(rowKeyValue).length === 0) {
              dispatch(updateEditorValue(rowKeyValue, column.key, editorValue));
            } else {
              dispatch(updateCellValue(rowKeyValue, column.key, editorValue));
              close();
            }
          }}
          onChange={(event) => {
            setValue(event.currentTarget.value);
          }}
        >
          {!editorValue && <option key=""></option>}
          {dataSet &&
            dataSet.map(({ key, value }) => <option key={key}>{value}</option>)}
        </select>
        {!editorValue && (
          <div
            className="absolute text-white p-2 text-xs w-max"
            style={{ backgroundColor: "#ff0c0c" }}
          >
            필수 입력 항목입니다.
          </div>
        )}
      </div>
    );
  };

  const FileInputEditor = ({ column, dispatch, rowKeyValue, value, tagId }) => {
    const close = () => {
      dispatch(closeEditor(rowKeyValue, column.key));
    };
    return (
      <div>
        <button
          type="button"
          onClick={() => document.getElementById(tagId).click()}
        >
          파일 선택
        </button>
        <input
          id={tagId}
          type="file"
          style={{ display: "none" }}
          onChange={(event) => {
            const id = uuid();

            setItem({
              ...item,
              file_info: [
                ...(item.file_info || []),
                { file: event.currentTarget.files[0], file_id: id },
              ],
            });
            dispatch(
              updateCellValue(
                rowKeyValue,
                column.key,
                event.currentTarget.files[0].name
              )
            );
            dispatch(updateCellValue(rowKeyValue, "file_id", id));
            close();
          }}
        />
      </div>
    );
  };

  const FileDeleteEditor = ({
    column,
    dispatch,
    rowKeyValue,
    value,
    rowData,
  }) => {
    return (
      <div>
        <FiDelete
          className="text-xl cursor-pointer"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(value + " 파일을 삭제하시겠습니까?")) {
              const fileItem = item.file_info.find(
                (fileItem) => fileItem.file_id === rowData.file_id
              );

              if (fileItem.url !== undefined && fileItem.url !== "") {
                setItem((prevItem) => {
                  const fileInfo = prevItem.file_info.map((fileItem) => {
                    if (fileItem.file_id === rowData.file_id) {
                      return { ...fileItem, del_yn: "1" };
                    } else {
                      return fileItem;
                    }
                  });
                  return { ...prevItem, file_info: fileInfo };
                });
                dispatch(updateCellValue(rowKeyValue, column.key, ""));
              }
            }
          }}
        />
      </div>
    );
  };

  const RowDeleteEditor = ({
    column,
    dispatch,
    rowKeyValue,
    value,
    rowData,
  }) => {
    return (
      <div>
        <SmallButton
          text="삭제"
          theme="dark-red"
          onClick={(event) => {
            // eslint-disable-next-line no-restricted-globals
            if (confirm("해당 행을 삭제하시겠습니까?")) {
              const fileItem = item.file_info.find(
                (fileItem) => fileItem.file_id === rowData.file_id
              );
              if (
                fileItem !== undefined &&
                fileItem.url !== undefined &&
                fileItem.url !== ""
              ) {
                setItem((prevItem) => {
                  const fileInfo = prevItem.file_info.map((fileItem) => {
                    if (fileItem.file_id === rowData.file_id) {
                      return { ...fileItem, del_yn: "1" };
                    } else {
                      return fileItem;
                    }
                  });
                  return { ...prevItem, file_info: fileInfo };
                });
              }
              dispatch(deleteRow(rowKeyValue));
            }
          }}
        />
      </div>
    );
  };

  const AddButton = ({ dispatch }) => {
    return (
      <SmallButton
        text="추가"
        theme="indigo"
        onClick={() => {
          dispatch(showNewRow());
        }}
      />
    );
  };

  const SaveButton = ({ dispatch, rowData }) => {
    const saveNewData = () => {
      const rowKeyValue = uuid();
      //rowData.type = "종료";

      dispatch(
        saveNewRow(rowKeyValue, {
          validate: true,
        })
      );
    };

    return (
      <div>
        <SmallButton text="저장" theme="dark-green" onClick={saveNewData} />
        <SmallButton
          text="삭제"
          theme="dark-red"
          onClick={() => dispatch(hideNewRow())}
        />
      </div>
    );
  };

  const addColumnStyle = {
    padding: "0.5rem",
    width: 70,
    textAlign: "center",
  };

  return (
    <>
      {item && <PageTitle>감정상세</PageTitle>}
      <form>
        <GreyCard>
          <div className="grid grid-cols-1 gap-y-2 overflow-x-auto">
            <div className="flex items-center gap-x-1">
              <YearPicker
                text="사건번호"
                id="year"
                onChange={handleChange}
                required={true}
                value={item.year}
              />
              <Input
                id="case_number_type"
                text=""
                type="text"
                value={(item && item.case_number_type) || ""}
                onChange={handleChange}
                errMsg={errMsg}
              />
              <Input
                id="case_number"
                text=""
                type="number"
                value={(item && item.case_number) || ""}
                onChange={handleChange}
                errMsg={errMsg}
              />
              <Input
                id="case_number_remark"
                text=""
                type="text"
                value={(item && item.case_number_remark) || ""}
                onChange={handleChange}
              />
            </div>

            <div className="grid md:grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2">
              <Input
                id="submission_date"
                text="예상감정료 제출일"
                type="date"
                value={(item && item.submission_date) || ""}
                onChange={handleChange}
              />
              <Input
                id="asign_date"
                text="감정인 지정일"
                type="date"
                value={(item && item.asign_date) || ""}
                onChange={handleChange}
                errMsg={errMsg}
              />
            </div>
            <Input
              id="title"
              text="사건명"
              type="text"
              value={(item && item.title) || ""}
              onChange={handleChange}
              errMsg={errMsg}
            />
            <div className="grid md:grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2">
              <SelectBox
                text="의뢰상태"
                id="status"
                options={status}
                value={
                  (item && {
                    value: item.status,
                    label: item.status,
                  }) ||
                  ""
                }
                isClearable={true}
                isSearchable={true}
                onChange={handleChange}
              />
              <SelectBox
                text="감정구분"
                id="appraisal_category"
                options={appraisal_category}
                value={
                  (item && {
                    value: item.appraisal_category,
                    label: item.appraisal_category,
                  }) ||
                  ""
                }
                isClearable={true}
                isSearchable={true}
                onChange={handleChange}
              />
            </div>
            <div className="grid md:grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2">
              <SelectBox
                text="법원"
                id="court"
                options={court}
                value={
                  (item && {
                    value: item.court,
                    label: item.court,
                  }) ||
                  ""
                }
                isClearable={true}
                isSearchable={true}
                onChange={handleChange}
              />
              <Input
                id="judge_panel"
                text="법원 부서"
                type="text"
                value={(item && item.judge_panel) || ""}
                onChange={handleChange}
              />
            </div>
            <div className="grid md:grid-cols-1 xl:grid-cols-3 gap-x-4 gap-y-2">
              <Input
                id="judge_panel_telno"
                text="법원 전화번호"
                type="text"
                value={(item && item.judge_panel_telno) || ""}
                onChange={handleChange}
              />
              <Input
                id="judge_panel_faxno"
                text="법원 팩스번호"
                type="text"
                value={(item && item.judge_panel_faxno) || ""}
                onChange={handleChange}
              />
              <Input
                id="judge_panel_email"
                text="법원 이메일"
                type="text"
                value={(item && item.judge_panel_email) || ""}
                onChange={handleChange}
              />
            </div>
            <div className="grid md:grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-2">
              <Input
                id="object_address"
                text="감정물 주소"
                type="text"
                value={(item && item.object_address) || ""}
                onChange={handleChange}
              />
              <Input
                id="object_remarks"
                text="비고"
                type="text"
                value={(item && item.object_remarks) || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </GreyCard>
        <section className="grid md:grid-cols-2 gap-4 gap-y-2">
          <div>
            <Subtitle>원고 감정료</Subtitle>
            <div className="border">
              <Table
                table={tablePlaintiffFee}
                columns={[
                  {
                    key: "type",
                    title: "분류",
                    dataType: DataType.String,
                  },
                  {
                    key: "date",
                    title: "일자",
                    dataType: DataType.Date,
                  },
                  {
                    key: "amount",
                    title: "금액",
                    style: { textAlign: "right" },
                    dataType: DataType.Number,
                  },
                  {
                    key: "addColumn",
                    style: addColumnStyle,
                  },
                ]}
                format={({ column, value }) => {
                  if (column.dataType === DataType.Date) {
                    const dateItem = new Date(value);
                    return (
                      dateItem &&
                      dateItem.toLocaleDateString("ko", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    );
                  } else if (column.key === "amount") {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                }}
                validation={({ column, value }) => {
                  if (
                    column.key === "type" ||
                    column.key === "date" ||
                    column.key === "amount"
                  ) {
                    return value ? "" : "필수 입력 항목입니다.";
                  }
                }}
                data={item.plaintiff_fee}
                editingMode={EditingMode.Cell}
                rowKeyField={"index"}
                childComponents={{
                  headCell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        return <AddButton {...props} />;
                      }
                    },
                  },
                  cellEditor: {
                    content: (props) => {
                      if (props.column.key === "type") {
                        return (
                          <TypeLookupEditor {...props} dataSet={feeCategory} />
                        );
                      } else if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <></>;
                        else return <SaveButton {...props} />;
                      }
                    },
                  },
                  cell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <RowDeleteEditor {...props} />;
                        else return null;
                      }
                    },
                  },
                }}
              />
            </div>
          </div>
          <div>
            <Subtitle>피고 감정료</Subtitle>
            <div className="border">
              <Table
                table={tableDefendantFee}
                columns={[
                  {
                    key: "type",
                    title: "분류",
                    dataType: DataType.String,
                  },
                  {
                    key: "date",
                    title: "일자",
                    dataType: DataType.Date,
                  },
                  {
                    key: "amount",
                    title: "금액",
                    style: { textAlign: "right" },
                    dataType: DataType.Number,
                  },
                  {
                    key: "addColumn",
                    style: addColumnStyle,
                  },
                ]}
                format={({ column, value }) => {
                  if (column.dataType === DataType.Date) {
                    const dateItem = new Date(value);
                    return (
                      dateItem &&
                      dateItem.toLocaleDateString("ko", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    );
                  } else if (column.key === "amount") {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                }}
                validation={({ column, value }) => {
                  if (
                    column.key === "type" ||
                    column.key === "date" ||
                    column.key === "amount"
                  ) {
                    return value ? "" : "필수 입력 항목입니다.";
                  }
                }}
                data={item.defendant_fee}
                editingMode={EditingMode.Cell}
                rowKeyField={"index"}
                childComponents={{
                  headCell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        return <AddButton {...props} />;
                      }
                    },
                  },
                  cellEditor: {
                    content: (props) => {
                      if (props.column.key === "type") {
                        return (
                          <TypeLookupEditor {...props} dataSet={feeCategory} />
                        );
                      } else if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <></>;
                        else return <SaveButton {...props} />;
                      }
                    },
                  },
                  cell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <RowDeleteEditor {...props} />;
                        else return null;
                      }
                    },
                  },
                }}
              />
            </div>
          </div>
        </section>

        <div>
          <Subtitle>첨부파일</Subtitle>
          <div className="border">
            <Table
              table={tableRequestFiles}
              columns={[
                {
                  key: "type",
                  title: "파일구분",
                  dataType: DataType.String,
                },
                {
                  key: "attachment_file",
                  title: "파일첨부",
                  dataType: DataType.String,
                  isEditable: false,
                },
                {
                  key: "file_id",
                  title: "파일id",
                  dataType: DataType.String,
                  visible: false,
                },
              ]}
              data={item.request_files}
              rowKeyField={"type"}
              childComponents={{
                cell: {
                  content: (props) => {
                    if (props.column.key === "attachment_file") {
                      if (
                        (props.rowData.attachment_file === undefined ||
                          props.rowData.attachment_file === "") &&
                        (props.rowData.file_id === undefined ||
                          props.rowData.file_id === "")
                      )
                        return (
                          <FileInputEditor
                            {...props}
                            tagId={"request_files_file_input"}
                          />
                        );
                      else {
                        const fileItem = item.file_info.find(
                          (fileItem) =>
                            fileItem.file_id === props.rowData.file_id
                        );
                        const fileUrl = fileItem ? fileItem.url : "";

                        return (
                          <div className="flex items-center gap-3">
                            <label
                              onClick={async (event) => {
                                event.preventDefault();
                                const realUrl = await getFileUrl(fileUrl);

                                if (realUrl.length === 0) {
                                  alert("파일을 다운로드할 수 없습니다.");
                                  return;
                                }

                                const response = await fetch(realUrl[0]);
                                const blob = await response.blob();

                                const link = document.createElement("a");
                                link.href = window.URL.createObjectURL(blob);
                                link.setAttribute("download", realUrl[1]); // 파일 이름을 지정할 수 있음
                                link.style.display = "none";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                            >
                              {props.value !== "" ? props.value : "삭제 중"}
                            </label>

                            {fileUrl && props.rowData.attachment_file && (
                              <FileDeleteEditor {...props} />
                            )}
                          </div>
                        );
                      }
                    }
                  },
                },
              }}
            />
          </div>
        </div>

        <hr className="my-5"></hr>
        {/* <div className="border-t my-5"></div> */}

        <section className="mb-6 max-w-full">
          <Subtitle>당사자 정보</Subtitle>
          <div className="grid grid-cols-2 gap-4 bg-light-gray6  py-2 px-4">
            <div className="grid grid-cols-1 gap-y-2">
              <Input
                id="plaintiff_name"
                text="원고명"
                type="text"
                value={(item && item.plaintiff_name) || ""}
                onChange={handleChange}
              />
              <Input
                id="plaintiff_telno"
                text="원고 연락처"
                type="text"
                value={(item && item.plaintiff_telno) || ""}
                onChange={handleChange}
              />
              <Input
                id="plaintiff_rep_name"
                text="원고 대리인명"
                type="text"
                value={(item && item.plaintiff_rep_name) || ""}
                onChange={handleChange}
              />
              <Input
                id="plaintiff_rep_telno"
                text="원고 대리인 연락처"
                type="text"
                value={(item && item.plaintiff_rep_telno) || ""}
                onChange={handleChange}
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <Input
                id="defendant_name"
                text="피고명"
                type="text"
                value={(item && item.defendant_name) || ""}
                onChange={handleChange}
              />
              <Input
                id="defendant_telno"
                text="피고 연락처"
                type="text"
                value={(item && item.defendant_telno) || ""}
                onChange={handleChange}
              />
              <Input
                id="defendant_rep_name"
                text="피고 대리인명"
                type="text"
                value={(item && item.defendant_rep_name) || ""}
                onChange={handleChange}
              />
              <Input
                id="defendant_rep_telno"
                text="피고 대리인 연락처"
                type="text"
                value={(item && item.defendant_rep_telno) || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </section>

        <section className="mb-6">
          <Subtitle>감정진행현황</Subtitle>
          <div className="border">
            <Table
              table={tableAppraisalProgress}
              columns={[
                {
                  key: "type",
                  title: "진행구분",
                  dataType: DataType.String,
                },
                {
                  key: "start_date",
                  title: "시작일",
                  dataType: DataType.Date,
                },
                {
                  key: "end_date",
                  title: "종료일",
                  dataType: DataType.Date,
                },
                {
                  key: "remarks",
                  title: "비고",
                  dataType: DataType.String,
                },
                {
                  key: "attachment_file",
                  title: "파일첨부",
                  dataType: DataType.String,
                  isEditable: false,
                },
                {
                  key: "file_id",
                  title: "파일id",
                  dataType: DataType.String,
                  visible: false,
                },
                {
                  key: "addColumn",
                  style: addColumnStyle,
                },
              ]}
              format={({ column, value }) => {
                if (column.dataType === DataType.Date) {
                  const dateItem = new Date(value);
                  return (
                    dateItem &&
                    dateItem.toLocaleDateString("ko", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  );
                }
              }}
              validation={({ column, value }) => {
                if (
                  column.key === "type" ||
                  column.key === "start_date" ||
                  column.key === "end_date"
                ) {
                  return value ? "" : "필수 입력 항목입니다.";
                }
              }}
              data={item.appraisal_progress}
              editingMode={EditingMode.Cell}
              rowKeyField={"index"}
              childComponents={{
                headCell: {
                  content: (props) => {
                    if (props.column.key === "addColumn") {
                      return <AddButton {...props} />;
                    }
                  },
                },
                cellEditor: {
                  content: (props) => {
                    if (props.column.key === "type") {
                      return (
                        <TypeLookupEditor
                          {...props}
                          dataSet={appraisal_progress}
                        />
                      );
                    } else if (props.column.key === "addColumn") {
                      if (Object.entries(props.rowKeyValue).length > 0)
                        return <></>;
                      else return <SaveButton {...props} />;
                    }
                  },
                },
                cell: {
                  content: (props) => {
                    if (props.column.key === "addColumn") {
                      if (Object.entries(props.rowKeyValue).length > 0)
                        return <RowDeleteEditor {...props} />;
                      else return null;
                    } else if (props.column.key === "attachment_file") {
                      if (Object.entries(props.rowKeyValue).length === 0) {
                        return <></>;
                      } else if (
                        (props.rowData.attachment_file === undefined ||
                          props.rowData.attachment_file === "") &&
                        (props.rowData.file_id === undefined ||
                          props.rowData.file_id === "")
                      )
                        return (
                          <FileInputEditor
                            {...props}
                            tagId={"appraisal_progress_file_input"}
                          />
                        );
                      else {
                        const fileItem =
                          item.file_info &&
                          item.file_info.find(
                            (fileItem) =>
                              fileItem.file_id === props.rowData.file_id
                          );
                        const fileUrl = fileItem ? fileItem.url : "";

                        return (
                          <div className="flex items-center gap-3">
                            <label
                              onClick={async (event) => {
                                event.preventDefault();
                                const realUrl = await getFileUrl(fileUrl);

                                if (realUrl.length === 0) {
                                  alert("파일을 다운로드할 수 없습니다.");
                                  return;
                                }

                                const response = await fetch(realUrl[0]);
                                const blob = await response.blob();

                                const link = document.createElement("a");
                                link.href = window.URL.createObjectURL(blob);
                                link.setAttribute("download", realUrl[1]); // 파일 이름을 지정할 수 있음
                                link.style.display = "none";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                            >
                              {props.value !== "" ? props.value : "삭제 중"}
                            </label>

                            {fileUrl && props.rowData.attachment_file && (
                              <FileDeleteEditor {...props} />
                            )}
                          </div>
                        );
                      }
                    }
                  },
                },
              }}
            />
          </div>
        </section>

        <section className="mb-6">
          <Subtitle>재판진행현황</Subtitle>
          <div className="border">
            <Table
              table={tableTrialProgress}
              columns={[
                {
                  key: "type",
                  title: "진행구분",
                  dataType: DataType.String,
                },
                {
                  key: "date",
                  title: "일자",
                  dataType: DataType.Date,
                },

                {
                  key: "remarks",
                  title: "비고",
                  dataType: DataType.String,
                },
                {
                  key: "addColumn",
                  style: addColumnStyle,
                },
              ]}
              format={({ column, value }) => {
                if (column.dataType === DataType.Date) {
                  const dateItem = new Date(value);
                  return (
                    dateItem &&
                    dateItem.toLocaleDateString("ko", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  );
                }
              }}
              validation={({ column, value }) => {
                if (column.key === "type" || column.key === "date") {
                  return value ? "" : "필수 입력 항목입니다.";
                }
              }}
              data={item.trial_progress}
              editingMode={EditingMode.Cell}
              rowKeyField={"index"}
              childComponents={{
                headCell: {
                  content: (props) => {
                    if (props.column.key === "addColumn") {
                      return <AddButton {...props} />;
                    }
                  },
                },
                cellEditor: {
                  content: (props) => {
                    if (props.column.key === "type") {
                      return (
                        <TypeLookupEditor {...props} dataSet={trial_progress} />
                      );
                    } else if (props.column.key === "addColumn") {
                      if (Object.entries(props.rowKeyValue).length > 0)
                        return <></>;
                      else return <SaveButton {...props} />;
                    }
                  },
                },
                cell: {
                  content: (props) => {
                    if (props.column.key === "addColumn") {
                      if (Object.entries(props.rowKeyValue).length > 0)
                        return <RowDeleteEditor {...props} />;
                      else return null;
                    }
                  },
                },
              }}
            />
          </div>
        </section>

        <section className="mb-6">
          <Subtitle>감정자료</Subtitle>
          <div className="border">
            <Table
              table={tableAppraisalMaterials}
              columns={[
                {
                  key: "parties",
                  title: "당사자",
                  dataType: DataType.String,
                },
                {
                  key: "date",
                  title: "일자",
                  dataType: DataType.Date,
                },
                {
                  key: "remarks",
                  title: "비고",
                  dataType: DataType.String,
                },
                {
                  key: "attachment_file",
                  title: "파일첨부",
                  dataType: DataType.String,
                  isEditable: false,
                },
                {
                  key: "file_id",
                  title: "파일id",
                  dataType: DataType.String,
                  visible: false,
                },
                {
                  key: "addColumn",
                  style: addColumnStyle,
                },
              ]}
              format={({ column, value }) => {
                if (column.dataType === DataType.Date) {
                  const dateItem = new Date(value);
                  return (
                    dateItem &&
                    dateItem.toLocaleDateString("ko", {
                      month: "2-digit",
                      day: "2-digit",
                      year: "numeric",
                    })
                  );
                }
              }}
              validation={({ column, value }) => {
                if (column.key === "parties" || column.key === "date") {
                  return value ? "" : "필수 입력 항목입니다.";
                }
              }}
              data={item.appraisal_materials}
              editingMode={EditingMode.Cell}
              rowKeyField={"index"}
              childComponents={{
                headCell: {
                  content: (props) => {
                    if (props.column.key === "addColumn") {
                      return <AddButton {...props} />;
                    }
                  },
                },
                cellEditor: {
                  content: (props) => {
                    if (props.column.key === "parties") {
                      return <TypeLookupEditor {...props} dataSet={parties} />;
                    } else if (props.column.key === "addColumn") {
                      if (Object.entries(props.rowKeyValue).length > 0)
                        return <></>;
                      else return <SaveButton {...props} />;
                    }
                  },
                },
                cell: {
                  content: (props) => {
                    if (props.column.key === "addColumn") {
                      if (Object.entries(props.rowKeyValue).length > 0)
                        return <RowDeleteEditor {...props} />;
                      else return null;
                    } else if (props.column.key === "attachment_file") {
                      if (Object.entries(props.rowKeyValue).length === 0) {
                        return <></>;
                      } else if (
                        (props.rowData.attachment_file === undefined ||
                          props.rowData.attachment_file === "") &&
                        (props.rowData.file_id === undefined ||
                          props.rowData.file_id === "")
                      )
                        return (
                          <FileInputEditor
                            {...props}
                            tagId={"appraisal_materials_file_input"}
                          />
                        );
                      else {
                        const fileItem =
                          item.file_info &&
                          item.file_info.find(
                            (fileItem) =>
                              fileItem.file_id === props.rowData.file_id
                          );
                        const fileUrl = fileItem ? fileItem.url : "";

                        return (
                          <div className="flex items-center gap-3">
                            <label
                              onClick={async (event) => {
                                event.preventDefault();
                                const realUrl = await getFileUrl(fileUrl);

                                if (realUrl.length === 0) {
                                  alert("파일을 다운로드할 수 없습니다.");
                                  return;
                                }

                                const response = await fetch(realUrl[0]);
                                const blob = await response.blob();

                                const link = document.createElement("a");
                                link.href = window.URL.createObjectURL(blob);
                                link.setAttribute("download", realUrl[1]); // 파일 이름을 지정할 수 있음
                                link.style.display = "none";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              }}
                            >
                              {props.value !== "" ? props.value : "삭제 중"}
                            </label>

                            {fileUrl && props.rowData.attachment_file && (
                              <FileDeleteEditor {...props} />
                            )}
                          </div>
                        );
                      }
                    }
                  },
                },
              }}
            />
          </div>
        </section>

        <section className="grid md:grid-cols-2 gap-4 mb-4">
          <div>
            <Subtitle>원고 입금</Subtitle>
            <div className="border">
              <Table
                table={tablePlaintiffDeposits}
                columns={[
                  {
                    key: "date",
                    title: "일자",
                    dataType: DataType.Date,
                  },
                  {
                    key: "amount",
                    title: "금액",
                    style: { textAlign: "right" },
                    dataType: DataType.Number,
                  },
                  {
                    key: "addColumn",
                    style: addColumnStyle,
                  },
                ]}
                format={({ column, value }) => {
                  if (column.dataType === DataType.Date) {
                    const dateItem = new Date(value);
                    return (
                      dateItem &&
                      dateItem.toLocaleDateString("ko", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    );
                  } else if (column.key === "amount") {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                }}
                validation={({ column, value }) => {
                  if (column.key === "date" || column.key === "amount") {
                    return value ? "" : "필수 입력 항목입니다.";
                  }
                }}
                data={item.plaintiff_deposits}
                editingMode={EditingMode.Cell}
                rowKeyField={"index"}
                childComponents={{
                  headCell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        return <AddButton {...props} />;
                      }
                    },
                  },
                  cellEditor: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <></>;
                        else return <SaveButton {...props} />;
                      }
                    },
                  },
                  cell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <RowDeleteEditor {...props} />;
                        else return null;
                      }
                    },
                  },
                }}
              />
            </div>
          </div>
          <div>
            <Subtitle>피고 입금</Subtitle>
            <div className="border">
              <Table
                table={tableDefendantDeposits}
                columns={[
                  {
                    key: "date",
                    title: "일자",
                    dataType: DataType.Date,
                  },
                  {
                    key: "amount",
                    title: "금액",
                    style: { textAlign: "right" },
                    dataType: DataType.Number,
                  },
                  {
                    key: "addColumn",
                    style: addColumnStyle,
                  },
                ]}
                format={({ column, value }) => {
                  if (column.dataType === DataType.Date) {
                    const dateItem = new Date(value);
                    return (
                      dateItem &&
                      dateItem.toLocaleDateString("ko", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })
                    );
                  } else if (column.key === "amount") {
                    return value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  }
                }}
                validation={({ column, value }) => {
                  if (column.key === "date" || column.key === "amount") {
                    return value ? "" : "필수 입력 항목입니다.";
                  }
                }}
                data={item.defendant_deposits}
                editingMode={EditingMode.Cell}
                rowKeyField={"index"}
                childComponents={{
                  headCell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        return <AddButton {...props} />;
                      }
                    },
                  },
                  cellEditor: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <></>;
                        else return <SaveButton {...props} />;
                      }
                    },
                  },
                  cell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        if (Object.entries(props.rowKeyValue).length > 0)
                          return <RowDeleteEditor {...props} />;
                        else return null;
                      }
                    },
                  },
                }}
              />
            </div>
          </div>
        </section>

        <section className="grid grid-cols-1 bg-light-gray6 my-2 py-2 px-4">
          <div className="grid grid-cols-2 gap-4 ">
            <div className="grid grid-cols-1 gap-y-2">
              <Input
                id="total_plaintiff_fee"
                text="원고감정료 합계"
                value={
                  item && item.total_plaintiff_fee
                    ? item.total_plaintiff_fee.toLocaleString()
                    : ""
                }
                readOnly={true}
                className="text-right"
              />
              <Input
                id="total_plaintiff_remainders"
                text="원고잔금 합계"
                value={
                  item && item.total_plaintiff_remainders
                    ? item.total_plaintiff_remainders.toLocaleString()
                    : ""
                }
                readOnly={true}
                className="text-right"
              />
            </div>
            <div className="grid grid-cols-1 gap-y-2">
              <Input
                id="total_defendant_fee"
                text="피고감정료 합계"
                type="text"
                value={
                  item && item.total_defendant_fee
                    ? item.total_defendant_fee.toLocaleString()
                    : ""
                }
                readOnly={true}
                className="text-right"
              />
              <Input
                id="total_defendant_remainders"
                text="피고잔금 합계"
                type="text"
                value={
                  item && item.total_defendant_remainders
                    ? item.total_defendant_remainders.toLocaleString()
                    : ""
                }
                readOnly={true}
                className="text-right"
              />
            </div>
          </div>
          <hr className="my-3" />
          <div className="grid grid-cols-1 gap-y-2">
            <Input
              id="total_fee"
              text="총 감정료"
              type="text"
              value={
                item && item.total_fee ? item.total_fee.toLocaleString() : ""
              }
              readOnly={true}
              className="text-right"
            />
            <Input
              id="total_remainders"
              text="총 잔금"
              type="text"
              value={
                item && item.total_remainders
                  ? item.total_remainders.toLocaleString()
                  : ""
              }
              readOnly={true}
              className="text-right"
            />
          </div>
        </section>
        <div className="my-5">
          <Button
            text="저장"
            onClick={handleSubmit}
            theme="primary"
            size="full"
          />
        </div>
        <div className="my-5">
          <Button
            text="삭제"
            id="del_doc"
            onClick={handleSubmit}
            theme="delete"
            size="full"
          />
        </div>
      </form>
    </>
  );
}
